import React, { useCallback } from 'react'
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'

//Compnents
import ProductItem from '../Components/ProductItem';
import BannerCarousel from '../Components/BannerCarousel';
import ClashMarkerPurchaseWindow from '../Components/ClashMarkerPurchaseWindow';
import MindfulPurchaseWindow from '../Components/MindfulPurchaseWindow';

//Firebase
import { auth, database } from '../firebase/firebaseClient';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

//Images
import initialsLogo from '../images/TPSLogo+Initials.png'
import generalPFP from '../images/generalPFP.png'
import tpsFullLogo from '../images/TPSLogo+Name.png'
//import mindfulPB from '../images/mindfulProductBanner.jpg'
import constellationPB from '../images/constellationProductBanner.jpg'
import TPS_Gradient from '../images/TPS-Gradient.png'

//CSS
import '../css/StoreFront.css'
import SiteMap from '../Components/SiteMap';
import MobileNavigationWindow from '../Components/MobileNavigationWindow';
import { HiMenu } from 'react-icons/hi';


function StoreFront() {

    const [isLoaded, setLoading] = useState(false);
    const [isAuthed, setIsAuthed] = useState(false);
    const [googlePFP, setGooglePFP] = useState("");
    const [profileModal, setProfileModal] = useState(false);
    const [productNumber, setProductNumber] = useState(0);
    const [purchaseWindow, setPurchaseWindow] = useState(false);
    const loadingModal = useAnimation();
    ReactGA.initialize("G-SZLZLTXLRV");
    const navModalAnim = useAnimation();
    const [navModal, setNavModal] = useState(false);

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    const navigate = useNavigate();
    const location = useLocation();

    const LinkMotion = motion(Link);

    const fetchUserInformation = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            await getDoc(doc(database, "users", auth.currentUser.uid)).then((data) => {
                setGooglePFP(data.data().pfp);
                //console.log(googlePFP);
                resolve(200);
            }).catch((err) => {

            })
        })
    }, []);

    const purchaseWindowCallback = useCallback((name, params) => {

        params.delete("product")
        navigate({ search: params.toString() }, { replace: true });

        switch (name) {
            case "Mindful Mobile App":
                setProductNumber(0);
                break;
            case "Clash Marker":
            case "clashmarker":
                setProductNumber(1);
                break;
            default:
                break;
        }

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        document.body.style.overflowY = !purchaseWindow ? "hidden" : "visible";

        setPurchaseWindow(!purchaseWindow);

    }, [purchaseWindow, navigate])

    useEffect(() => {

        ReactGA.send({
            hitType: "pageview",
            page: "/store",
            title: "Store Front"
        });

        loadingModal.start("visible");
        navModalAnim.start("visible");

        const body = document.body;
        const previousOverflow = body.style.overflow;
        const previousPaddingRight = body.style.paddingRight;

        const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflowY = "hidden";

        const params = new URLSearchParams(location.search);

        loadingModal.start("hidden").then(() => {
            document.body.style.overflowY = "visible";
            document.body.style.paddingRight = '';
        });

        body.style.overflow = previousOverflow;
        body.style.paddingRight = previousPaddingRight;

        if (params.get("product") === "clashmarker") {
            document.scrollTop = 0;
            purchaseWindowCallback("clashmarker", params);
        }

        const unsub = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const promise = await fetchUserInformation();
                if (promise === 200) {
                    setIsAuthed(true);
                }
            }
            else {
                setIsAuthed(false);
            }
        });

        return () => unsub();

    }, [purchaseWindowCallback, loadingModal, isAuthed, fetchUserInformation, location.search, navModalAnim]);

    function onUpdate(latest) {
        if (latest.opacity === 0) {
            setLoading(true);
        }
    }

    function openPurchaseWindow(name) {

        switch (name) {
            case "Mindful Mobile App":
                setProductNumber(0);
                break;
            case "Clash Marker":
            case "clashmarker":
                setProductNumber(1);
                break;
            default:
                break;
        }

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        document.body.style.overflowY = !purchaseWindow ? "hidden" : "visible";
        setPurchaseWindow(!purchaseWindow);
    }

    function showProfileModal() {
        setProfileModal(!profileModal);
    }

    function signOutUser() {
        auth.signOut();
    }

    function showHideModal() {
        if (navModal) {
            navModalAnim.start("hidden").then(() => {
                setNavModal(false);
            });
        }
        else {
            setNavModal(true);
            navModalAnim.start({ x: 500 });
        }
    }

    const naval = { visible: { opacity: [0, 1] }, hidden: { opacity: [1, 0], transition: { duration: 0.5 } } }
    const loadal = { visible: { opacity: [0, 1] }, hidden: { opacity: [1, 0], transition: { duration: 1 } } }

    return (
        <div>
            {purchaseWindow && productNumber === 0 &&
                <MindfulPurchaseWindow closeWindow={openPurchaseWindow} />
            }
            {purchaseWindow && productNumber === 1 &&
                <ClashMarkerPurchaseWindow closeWindow={openPurchaseWindow} />
            }
            {!isLoaded &&
                <motion.div animate={loadingModal} variants={loadal} id="loadingCover" onUpdate={onUpdate}>
                    <motion.img src={tpsFullLogo} id={isMobile ? "loading-cover-img-mobile" : "loading-cover-img"} animate={{ opacity: [0, 1], transition: { repeat: Infinity, duration: 1.75, repeatType: "reverse" } }} />
                </motion.div>
            }
            <header>
                {!isMobile &&
                    <nav style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'column' }}>
                        <div id={"main-nav-bar-container"}>
                            <Link to={"/"}><img src={tpsFullLogo} id={"main-nav-bar-logo"} alt={"Transparent Software Logo"} /></Link>
                            <div id={"main-nav-bar-links-container"}>
                                <Link to={"/#whatweoffer"} className="link-hover"><p>Services</p></Link>
                                <Link to={"/#whatwedo"} className="link-hover"><p>What We Do</p></Link>
                                <Link to={"/#explore"} className="link-hover"><p>Our Work</p></Link>
                                <Link to={"/contactus"} id={"main-nav-bar-contact-button"}>
                                    <p>Contact Us</p>
                                </Link>
                                {isAuthed &&
                                    <div id={"sf-pfpImage-desktop"}>
                                        <motion.img whileHover={{ opacity: [1, 0.65] }} src={googlePFP ? googlePFP : generalPFP} id="sf-shoppingcart-img-desktop" alt="Profile Picture" referrerPolicy='no-referrer' onClick={showProfileModal} />
                                        {profileModal && isAuthed &&
                                            <motion.div animate={{ opacity: [0, 1] }} id="sf-profile-modal">
                                                <Link to={"/store/profile"} id="sf-viewprofilelink"><motion.p whileHover={{ opacity: [1, 0.65] }}>View Profile</motion.p></Link>
                                                <motion.button disabled={!isAuthed} onClick={signOutUser} whileHover={{ opacity: isAuthed ? [1, 0.65] : [0.25, 0.25] }} id="sf-signout-button" style={{ opacity: isAuthed ? 1 : 0.25 }}><p>Sign Out</p></motion.button>
                                            </motion.div>
                                        }
                                    </div>
                                }
                                {!isAuthed &&
                                    <LinkMotion whileHover={{ opacity: [1, 0.65] }} to={"/store/profile"} style={{ textDecoration: "none" }} id="sf-signin-button"><p>Sign In</p></LinkMotion>
                                }
                            </div>
                        </div>
                    </nav>
                }
                {isMobile &&
                    <nav className={"sf-store-nav-mobile"}>
                        <Link to={"/store"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile" />
                        </Link>
                        {isAuthed &&
                            <div id={"sf-pfpImage-mobile"}>
                                <motion.img whileHover={{ opacity: [1, 0.65] }} src={googlePFP ? googlePFP : generalPFP} id="sf-shoppingcart-img-mobile" alt="Profile Picture" referrerPolicy='no-referrer' onClick={showProfileModal} />
                                {profileModal && isAuthed &&
                                    <motion.div animate={{ opacity: [0, 1] }} id="sf-profile-modal-mobile">
                                        <Link id="sf-view-link-mobile" to={"/store/profile"}><motion.p whileHover={{ opacity: [1, 0.65] }}>View Profile</motion.p></Link>
                                        <motion.button disabled={!isAuthed} onClick={signOutUser} whileHover={{ opacity: isAuthed ? [1, 0.65] : [0.25, 0.25] }} id="sf-signout-button-mobile" style={{ opacity: isAuthed ? 1 : 0.25 }}><p>Sign Out</p></motion.button>
                                    </motion.div>
                                }
                            </div>
                        }
                        {!isAuthed &&
                            <LinkMotion whileHover={{ opacity: [1, 0.65] }} to={"/store/profile"} style={{ textDecoration: "none" }} id="sf-signin-button-mobile"><p>Sign In</p></LinkMotion>
                        }
                        <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={() => showHideModal()} />
                    </nav>
                }
            </header>
            {isMobile && navModal &&
                <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim} />
            }
            <main id={isMobile ? "sf-main-mobile" : "sf-main-desktop"}>
                <div style={{ width: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div id={isMobile ? "sf-featured-container-mobile" : "sf-featured-container-desktop"}>
                        <BannerCarousel />
                    </div>
                </div>
                <img src={TPS_Gradient} id={isMobile ? "sf-gradient-mobile" : "sf-gradient-desktop"} alt={""} />
                <section id="products" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div id={isMobile ? "sf-products-container-mobile" : "sf-products-container-desktop"}>
                        <div id={isMobile ? "sf-products-header-container-mobile" : "sf-products-header-container-desktop"}>
                            <h2>All Products</h2>
                        </div>
                        <motion.div animate={{ opacity: [0, 1], transition: { duration: 0.5, delay: 1 } }} id={isMobile ? "sf-product-items-container-mobile" : "sf-product-items-container-desktop"}>
                            {/*<ProductItem image={mindfulPB} openWindow={(product) => { document.scrollTop = 0; openPurchaseWindow(product); }} productName={"Mindful Mobile App"} description={"Daily Planner that organizes your day for you with mental well-being in mind."} unavailable={false} />*/}
                            <ProductItem image={constellationPB} openWindow={(product) => { document.scrollTop = 0; openPurchaseWindow(product); }} productName={"Clash Marker"} description={"Automatic tool for translating clashes from 3D coordination software to your models in AutoCAD, Revit, HydraCAD, BricsCAD, & sprinkCAD."} unavailable={false} />
                        </motion.div>
                    </div>
                </section>
            </main>
            <SiteMap isMobile={isMobile} />
        </div>
    )

}

export default StoreFront;