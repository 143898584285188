import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import "../css/MobileNavigationWindow.css"

function MobileNavigationWindow({naval, navModalAnim}) {
    return (
        <motion.div animate={navModalAnim} variants={naval} id="mobile-nav-list-container">
            <div className="mobile-nav-list-group-container">
                <motion.h3 animate={{ opacity: [0, 1] }}>General</motion.h3>
                <Link to={"/store"} style={{ color: "#000000" }}><motion.p animate={{ opacity: [0, 1], x: [-15, 0], transition: { duration: 0.75 } }}>TPS Store</motion.p></Link>
                <Link to={"/faq"} style={{ color: "#000000" }}><motion.p animate={{ opacity: [0, 1], x: [-15, 0], transition: { duration: 0.75 } }}>Product FAQs</motion.p></Link>
                <Link to={"/"} style={{ color: "#000000" }}><motion.p animate={{ opacity: [0, 1], x: [-15, 0], transition: { duration: 0.75 } }}>Home</motion.p></Link>
            </div>
            <div className="mobile-nav-list-group-container">
                <motion.h3 animate={{ opacity: [0, 1] }}>Product Information</motion.h3>
                <Link to={"/clashmarker"} style={{ color: "#000000" }}><motion.p animate={{ opacity: [0, 1], x: [-15, 0], transition: { duration: 0.75, delay: 0.1} }}>Clash Marker</motion.p></Link>
            </div>
            {/*<div className="mobile-nav-list-group-container">
                <motion.h3 animate={{ opacity: [0, 1] }}>Client Websites</motion.h3>
                <motion.a href="https://www.mindfulapp.info" target="_blank" rel="noreferrer"><motion.p animate={{ opacity: [0, 1], x: [-15, 0], transition: { duration: 0.75, delay: 0.2 } }}>Mindful Info Site</motion.p></motion.a>
                <motion.a href="https://www.maddiekeyes.com" target="_blank" rel="noreferrer"><motion.p animate={{ opacity: [0, 1], x: [-15, 0], transition: { duration: 0.75, delay: 0.3 } }}>Maddie Keyes Photography</motion.p></motion.a>
            </div>*/}
            <div className="mobile-nav-list-group-container">
                <motion.h3 animate={{ opacity: [0, 1] }}>Help</motion.h3>
                <Link to={"/contactus"} style={{ color: "#000000" }}><motion.p animate={{ opacity: [0, 1], x: [-15, 0], transition: { duration: 0.75, delay: 0.4 } }}>Contact Us</motion.p></Link>
                <Link to={"/legal"} style={{ color: "#000000" }}><motion.p animate={{ opacity: [0, 1], x: [-15, 0], transition: { duration: 0.75, delay: 0.5 } }}>Legal</motion.p></Link>
            </div>
        </motion.div>
    )
}

export default MobileNavigationWindow