import { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4'
import axios from 'axios';

import tpsLogo from '../images/TPSLogo+Name.png';
import icon from '../images/TPSLogo+Initials.png'

import '../css/Unsubscribe.css'
import SiteMap from '../Components/SiteMap';

export default function Unsubscribe(){
    
    ReactGA.initialize("G-SZLZLTXLRV");

    const[email, setEmail] = useState("");
    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    });

    useEffect(()=>{

        ReactGA.send({
            hitType: "pageview",
            page: "/unsubscribe",
            title: "Unsubscribe"
        });

        var form = document.getElementById(isMobile ? "form-mobile" : "form-desktop");
        function handleForm(event) { event.preventDefault(); } 
        form.addEventListener('submit', handleForm);

    }, [isMobile])

    const submitForm = () => {

        const params = new URLSearchParams(window.location.search);

        axios.post("https://removeemailfromemaillist-cg3iutb5oq-uc.a.run.app", {email_list: params.get("emailList"), email: email})
        .then((result)=>{
            if(result.status === 200){
                alert("Successfully unsubscribed.")
                setEmail("")
                window.location.href = "https://www.tps-technology.com"
            }
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    return(
        <div style={{width: '100%', height: '100vh',}}>
            <div id="fake-nav-container">
                <div>
                    <Link to={"/"} id={"home-link-container"}>
                        <img src={icon} id={isMobile ? "icon-img-mobile" : "icon-img"} alt="Transparent Software Logo"/>
                        <p>Return to Homepage</p>
                    </Link>
                </div>
            </div>
            <div id="error-page" style={{width: isMobile ? '90%' : undefined}}>
                <div id="header-container" style={{maxHeight: isMobile ? '35%' : undefined}}>
                    <img style={{width: isMobile ? '80%' : undefined}} id="successIcon-img" src={tpsLogo} alt="Checkmark in a green circle"/>
                    <h1 style={{fontSize: isMobile ? '7vw' : undefined}}>Sorry to see you go!</h1>
                </div>
                <form id={isMobile ? "form-mobile" : "form-desktop"}>
                    <label for="email-input">Enter email to unsubscribe:</label>
                    <input id="email-input" type="text" value={email} onChange={(e) => { setEmail(e.target.value); }}></input>
                    <motion.button whileHover={{ opacity: [1, 0.75] }} type="submit" onClick={submitForm}>Submit</motion.button>
                </form>
            </div>
            <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
                <SiteMap isMobile={isMobile}/>
            </div>
        </div>
    );
}