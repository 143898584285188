import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';

//CSS
import "../css/Checkout.css"

//Images
import constellationItemsIcon from "../images/constellationItemsIcon.jpg"

function ProductCheckoutItem({name, quantity, subscription}) {

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    const [totalAmtForProduct, setTotalAmtForProduct] = useState(0);

    useEffect(()=>{
        switch (subscription) {
            case "$599.99 / year":              
                setTotalAmtForProduct(parseFloat(599.99 * parseFloat(quantity)));
                break;
            case "$64.99 / month":
                setTotalAmtForProduct(parseFloat(64.99 * parseFloat(quantity)));
                break;
            default:
                break;
        }
    }, [subscription, quantity])

    return (
        <div id={isMobile ? "cout-product-container-mobile" : "cout-product-container-desktop"}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center", width: '70%'}}>
                <img src={constellationItemsIcon} id={isMobile ? "cout-product-icon-mobile" : "cout-product-icon-desktop"} alt={`${name} logo preview`}/>
                <div>
                    <h5>{name}</h5>
                    <p>{subscription}</p>
                </div>
            </div>
            <p style={{width: '40%', textAlign: 'center'}}> x {quantity} {parseFloat(quantity) > 1 ? 'licenses' : 'license'}</p>
            <p style={{width: '25%', textAlign: 'right', }}>${totalAmtForProduct}</p>
        </div>
    )

}

export default ProductCheckoutItem;