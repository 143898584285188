import * as THREE from 'three';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { MTLLoader } from 'three/addons/loaders/MTLLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { useRef, useState } from 'react';
import ReactGA from 'react-ga4'
import { useMediaQuery } from 'react-responsive';

import '../css/ProductPageClashMarker.css'

function RevitModel() {
    const refContainer = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    ReactGA.initialize("G-SZLZLTXLRV");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    const loadModel = () => {

        ReactGA.event({
            category: "Clash Marker",
            action: "Interactive Revit Model Loaded",
        });

        console.log(window.innerHeight);
        console.log(window.innerWidth);

        setLoading(true);
    
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0x222933);
        let camera = null;
    
        if(isMobile){
            camera = new THREE.PerspectiveCamera(75, 300 / 300, 0.01, 5000);
        }
        else{
            camera = new THREE.PerspectiveCamera(75, 1000 / 700, 0.01, 5000);
        }

        camera.position.set(0, 0, 100);
    
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        if(isMobile){
            renderer.setSize(300, 300)
        }
        else{
            renderer.setSize(1000, 700);
        }
    
        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(5, 5, 5).normalize();
        scene.add(light);
    
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);
    
        if (refContainer.current) {
            refContainer.current.appendChild(renderer.domElement); // Append the renderer to the container
        }
    
        const mtlLoader = new MTLLoader();
        mtlLoader.load('/models/REVIT-MODEL-FINAL-OPT.mtl', (materials) => {
            materials.preload();
    
            const objLoader = new OBJLoader();
            objLoader.setMaterials(materials);
            objLoader.load(
                '/models/REVIT-MODEL-FINAL-OPT.obj',
                (object) => {
                    scene.add(object);
    
                    const box = new THREE.Box3().setFromObject(object);
                    const size = box.getSize(new THREE.Vector3());
                    const center = box.getCenter(new THREE.Vector3());
    
                    const cameraDistance = size.length() * 1.5;
                    camera.position.set(0, 0, cameraDistance);
                    camera.lookAt(center);
    
                    controls.target.copy(center);
                    controls.update();
    
                    setLoaded(true);
                    setLoading(false);
                },
                (xhr) => {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                },
                (error) => {
                    console.log('An error happened', error);
                    setLoading(false);
                }
            );
        });
    
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
        controls.minDistance = 5;
        controls.maxDistance = 12;
    
        const resizeListener = () => {

            if(isMobile){
                camera.aspect = 300 / 300;
                camera.updateProjectionMatrix();
                renderer.setSize(300, 300);
            }
            else{
                if(window.innerWidth < 1800 && window.innerWidth > 1000){
                    camera.aspect = 800 / 500;
                    camera.updateProjectionMatrix();
                    renderer.setSize(800, 500);
                }

                if(window.innerWidth < 1000){
                    camera.aspect = 500 / 300;
                    camera.updateProjectionMatrix();
                    renderer.setSize(500, 300);
                }

                if(window.innerWidth > 1800){
                    camera.aspect = 1000 / 700;
                    camera.updateProjectionMatrix();
                    renderer.setSize(1000, 700);
                }
            }
        };
        window.addEventListener('resize', resizeListener);
    
        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };
        animate();
    
        return () => {
            renderer.dispose();
            controls.dispose();
            window.removeEventListener('resize', resizeListener);
            if (refContainer.current) {
                refContainer.current.removeChild(renderer.domElement);
            }
        };
    };
    

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                borderRadius: 10,
                flexDirection: 'column',
                position: 'relative',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
                overflow: 'hidden',
            }}
        >
            {!loading && !loaded && (
                <button  style={{border: '1px solid #3294ce', backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center', borderRadius: 50}} onClick={loadModel}>
                    <p style={{fontFamily: "'Inter', serif", fontWeight: 700, margin: 0, paddingBlock: 10, paddingInline: 15, boxSizing: isMobile ? "border-box" : undefined, color: '#3294ce', cursor: 'pointer',}}>Load Model</p>
                </button>
            )}
            {loading && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className="spinner"></div>
                    <p style={{fontFamily: "'Inter', serif", fontWeight: 700, margin: 0, paddingBlock: 10, paddingInline: 15, boxSizing: isMobile ? "border-box" : undefined, }}>
                        {isMobile ? 'You can use your fingers to zoom and pan in the model.' : 'You can use your mouse and scroll wheel to zoom and pan in the model.'}
                    </p>
                </div>
            )}
            <div
                ref={refContainer}
                className={loaded ? "model-fade-in" : "model-hidden"}
            >

            </div>
        </div>

    );
}

export default RevitModel;
