import React, { useCallback } from 'react'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import axios from 'axios'
import ReactGA from 'react-ga4'

//Components
import MobileNavigationWindow from '../Components/MobileNavigationWindow';
import SiteMap from '../Components/SiteMap';
import RevitModel from '../Components/RevitModel';

//Icons
import { HiMenu } from 'react-icons/hi';

//CSS
import '../css/ProductPageMindful.css'
import '../css/ProductPageClashMarker.css'

//Images
import initialsLogo from '../images/TPSLogo+Initials.png'
import tpsFullLogo from '../images/TPSLogo+Name.png'
import MainNavBar from '../Components/MainNavBar';
import { ReactComponent as Stars } from '../images/Stars.svg';
import { ReactComponent as CMPlanet } from '../images/CM-Planet.svg';
import cmSS from '../images/clashMarkerSS.png'
import clashMarkerMockup from '../images/clashMarkerMockup.png'
import autocadLogo from '../images/autocadlogo.png'
import bricscadLogo from '../images/bricscadlogo.png'
import revitLogo from '../images/revitlogo.png'
import hydracadLogo from '../images/HydraCADLogo.png'
import srpinkcadLogo from '../images/SprinkCADLogo.png'
import CM_Gradient from '../images/CM-Gradient.png'


function ProductPageClashMarker() {

    const [isLoaded, setLoading] = useState(false);
    const loadingModal = useAnimation();
    const navModalAnim = useAnimation();
    const [navModal, setNavModal] = useState(false);
    //const [email, setEmail] = useState("");
    //const [lastSubmittal, setLastSubmittal] = useState(0)
    const [markedClashes, setMarkedClashes] = useState(0);
    const [markedViewpoints, setMarkedViewpoints] = useState(0);
    ReactGA.initialize("G-SZLZLTXLRV");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    const trackMarkedClashes = useCallback(async () => {

        await axios.get("https://getmarkingdata-cg3iutb5oq-uc.a.run.app").then((resp) => {
            //console.log(resp.data.stats)
            setMarkedClashes(addCommas(resp.data.stats.numOfClashesMarked))
            setMarkedViewpoints(addCommas(resp.data.stats.numOfViewpointsMarked))
        })

    }, []);

    useEffect(() => {

        ReactGA.send({
            hitType: "pageview",
            page: "/clashmarker",
            title: "Clash Marker Product Page"
        });

        loadingModal.start("visible");
        navModalAnim.start("visible");

        trackMarkedClashes().then(() => {
            loadingModal.start("hidden");
        })

    }, [loadingModal, navModalAnim, trackMarkedClashes])

    const loadal = { visible: { opacity: [0, 1] }, hidden: { opacity: [1, 0], transition: { duration: 1 } } }
    const naval = { visible: { opacity: [0, 1] }, hidden: { opacity: [1, 0], transition: { duration: 0.5 } } }

    function onUpdate(latest) {
        if (latest.opacity === 0) {
            setLoading(true);
        }
    }

    function showHideModal() {
        if (navModal) {
            navModalAnim.start("hidden").then(() => {
                setNavModal(false);
            });
        }
        else {
            setNavModal(true);
            navModalAnim.start({ x: 500 });
        }
    }

    function addCommas(num) {

        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /*function addtoMailingList(){
        if(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)){
            if(lastSubmittal === 0){
                axios.post("https://newemailforemaillist-cg3iutb5oq-uc.a.run.app", {email_list: "clashmarker", email: email}).then((resp)=>{
                    alert(resp.data.msg)
                })
                .catch((err)=>{
                    console.log(err);
                })
                setLastSubmittal(new Date().getTime())
            }
            else{
                if(new Date().getTime() - lastSubmittal < 5000){
                    alert("You've tried again too quickly. Wait a little and try again.")
                    setLastSubmittal(new Date().getTime())
                }
                else{
                    axios.post("https://newemailforemaillist-cg3iutb5oq-uc.a.run.app", {email_list: "clashmarker", email: email}).then((resp)=>{
                        alert(resp.data.msg)
                    })
                    .catch((err)=>{
                        console.log(err);
                    })
                    setLastSubmittal(new Date().getTime())
                }
            }
        }
        else{
            alert("The email you've entered is in an invalid format. Please enter an email in the following format: youremail@domain.com")
        }

        setEmail("")
    }*/

    return (
        <div style={{backgroundColor: "#101010"}}>
            {!isLoaded &&
                <motion.div animate={loadingModal} variants={loadal} id="loadingCover" onUpdate={onUpdate}>
                    <motion.img src={tpsFullLogo} id={isMobile ? "loading-cover-img-mobile" : "loading-cover-img"} animate={{ opacity: [0, 1], transition: { repeat: Infinity, duration: 1.75, repeatType: "reverse" } }} />
                </motion.div>
            }
            <header>
                {!isMobile &&
                    <MainNavBar />
                }
                {isMobile &&
                    <nav className={"nav-mobile"}>
                        <Link to={"/"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile" />
                        </Link>
                        <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={() => showHideModal()} />
                    </nav>
                }
            </header>
            {isMobile && navModal &&
                <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim} />
            }
            <main className={isMobile ? 'cmpp-container-mobile' : 'cmpp-container-desktop'}>
                <Stars id={isMobile ? 'cmpp-stars-mobile' : 'cmpp-stars-desktop'} />
                {!isMobile && <div style={{ position: 'absolute', width: '100%', zIndex: 1}}>
                    <CMPlanet id={isMobile ? 'cmpp-planet-mobile' : 'cmpp-planet-desktop'} style={{width: isMobile ? '80vw' : '50vw', height: 'auto', transform: isMobile ? 'translate(-50vw, 80vh)' : 'translate(85vw, -30vh)' }} />
                    <CMPlanet id={isMobile ? 'cmpp-planet-mobile' : 'cmpp-planet-desktop'} style={{width: isMobile ? '80vw' : '50vw', height: 'auto', transform: isMobile ? 'translate(75vw, -50vh)' : 'translate(-35vw, -25vh)' }} />
                    <CMPlanet id={isMobile ? 'cmpp-planet-mobile' : 'cmpp-planet-desktop'} style={{width: isMobile ? '80vw' : '50vw', height: 'auto', transform: isMobile ? 'translate(75vw, 50vh)' : 'translate(85vw, 0vh)' }} />
                </div>}
                <div className={isMobile ? 'cmpp-landing-text-container-mobile' : 'cmpp-landing-text-container-desktop'}>
                    <div style={{width: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 5,}}>
                        <div id={isMobile ? "cmpp-landing-subtext-mobile" : "cmpp-landing-subtext-desktop"}><p className="gradient-text">Available Now</p></div>
                        <h1>Clash Marker</h1>
                        <p>The power to mark clashes, now at your fingertips.</p>
                        <img src={clashMarkerMockup} id={isMobile ? 'cmpp-landing-image-mobile' : 'cmpp-landing-image-desktop'} alt={"AutoCAD drawing with marked clashes by Clash Marker."}/>
                    </div>
                </div>
                <div id={isMobile ? "cmpp-buytrial-section-mobile" : "cmpp-buytrial-section-desktop"}>
                    <div id={isMobile ? "cmpp-buytrial-container-mobile" : "cmpp-buytrial-container-desktop"}>
                        <div style={{marginBottom: '32px'}}>
                            <h3>Clash Marker is available here:</h3>
                            <motion.button onClick={() => {window.location.href = window.location.origin + "/store?product=clashmarker"}} whileHover={{opacity: [1, 0.75]}} id={isMobile ? "cmpp-buy-link-mobile" : "cmpp-buy-link-desktop"}>
                                <h4 className="gradient-text">Get Clash Marker</h4>
                            </motion.button>
                        </div>
                        <div>
                            <h3>Get a 2 week free trial here:</h3>
                            <motion.button onClick={() => {window.location.href = window.location.origin + "/clashmarkerfreetrial"}} whileHover={{opacity: [1, 0.75]}} id={isMobile ? "cmpp-trial-link-mobile" : "cmpp-trial-link-desktop"}>
                                <h4 className="gradient-text">Try Clash Marker</h4>
                            </motion.button>
                        </div>
                    </div>
                </div>
                <div id={isMobile ? "cmpp-easter-section-mobile" : "cmpp-easter-section-desktop"}>
                    <div id={isMobile ? "cmpp-easter-container-mobile" : "cmpp-easter-container-desktop"}>
                        <h3>This isn't Easter. <span className={"gradient-text"}>No more scavenger hunts.</span></h3>
                        <p>In the fast-paced, stressful industry that is construction design, anything that can be streamlined should be streamlined. In the world of BIM, finding clashes is a time consuming process that causes headaches for many designers, engineers, and coordinators. Clash Marker cuts down the time it takes to find clashes, resulting in quicker clash resolution and faster project turnaround.</p>
                        <div id={isMobile ? "cmpp-easter-videos-container-mobile" : "cmpp-easter-videos-container-desktop"}>
                            <div>
                                <h4>Clash Marker Overview</h4>
                                <iframe style={{width: '100%', height: undefined, aspectRatio: 16/9}} src="https://www.youtube.com/embed/vc_3oYide4E?si=eMvOT6Lea9_XkYkG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <div>
                                <h4>Clash Marker Full Uncut Demo</h4>
                                <iframe style={{width: '100%', height: undefined, aspectRatio: 16/9}} src="https://www.youtube.com/embed/B5cQWMCeAIM?si=gHhXHajFXfZUxW6x" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                        <p>Clash Marker utilizes data from your clash coordination software to mark where your clashes are directly on your model. It also uses that data to visualize your viewpoints on your model. Allowing you to see their exact location and orientation. Clash Marker’s Revit plug-in includes tools to help you strip down your model and focus on individual clashes and viewpoints.</p>

                    </div>
                </div>
                <div id={isMobile ? "cmpp-clashes-section-mobile" : "cmpp-clashes-section-desktop"}>
                    <div id={isMobile ? "cmpp-clashes-container-mobile" : "cmpp-clashes-container-desktop"}>
                        <div id={isMobile ? "cmpp-clashes-live-container-mobile" : "cmpp-clashes-live-container-desktop"}>
                            <p>Live</p>
                            <div style={{width: 10, height: 10, backgroundColor: '#3294ce', borderRadius: 50,}}></div>
                        </div>
                        <div id={isMobile ? "cmpp-clashes-child-container-mobile" : "cmpp-clashes-child-container-desktop"}>
                            <div>
                                <h5>Number of Clashes Marked:</h5>
                                <p className='gradient-text' style={{textShadow: 'none'}}>{markedClashes}</p>
                            </div>
                            <div>
                                <h5>Number of Viewpoints Marked:</h5>
                                <p className='gradient-text' style={{textShadow: 'none'}}>{markedViewpoints}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={isMobile ? "cmpp-why-section-mobile" : "cmpp-why-section-desktop"}>
                    <div id={isMobile ? "cmpp-why-container-mobile" : "cmpp-why-container-desktop"}>
                        <div>
                            <h4>Why do you need Clash Marker?</h4>
                            <p>
                                Its simple. You spend approximately 70% of your time digging around your model to a single clash so that then you can spend the other 30% of your time working on clearing it.<br/><br/>This process is repeated for every clash you have, which on a large model, could mean over a thousand different clashes in total.<br/><br/>Clash Marker gives you back that time you would spend on finding that clash, and it does it for all your clashes all at once. Now all the time you spent looking for clashes can be used for more productive tasks.
                            </p>
                            <p>
                                Clash Marker <span className="gradient-text" style={{textShadow: "0px 0px 5px rgba(255,255,255, 0.15)"}}>speeds up the clash resolution process</span> by approximately <span className="gradient-text" style={{textShadow: "0px 0px 5px rgba(255,255,255, 0.15)"}}>30-50%.</span>
                            </p>
                        </div>
                        <img src={cmSS} alt={"Clash Marker UI"}/>
                    </div>
                </div>
                <div id={isMobile ? "cmpp-compatability-section-mobile" : "cmpp-compatability-section-desktop"}>
                    <h4>Compatability</h4>
                    <p>Clash Marker currently supports the following software:</p>
                    <div id={isMobile ? "cmpp-compatability-software-mobile" : "cmpp-compatability-software-desktop"}>
                        <img src={autocadLogo} alt={"AutoCAD Logo"} style={{width: isMobile ? '18%' : '15%'}} className={isMobile ? "cmpp-software-image-mobile" : "cmpp-software-image-desktop"}/>
                        <img src={bricscadLogo} alt={"BricsCAD Logo"} style={{width: isMobile ? '18%' : '13%'}} className={isMobile ? "cmpp-software-image-mobile" : "cmpp-software-image-desktop"}/>
                        {!isMobile && <img src={srpinkcadLogo} alt={"sprinkCAD Logo"} style={{width: isMobile ? '60%' : '25%'}} className={isMobile ? "cmpp-software-image-mobile" : "cmpp-software-image-desktop"}/>}
                        <img src={revitLogo} alt={"Revit Logo"} style={{width: isMobile ? '21%' : '16%'}} className={isMobile ? "cmpp-software-image-mobile" : "cmpp-software-image-desktop"}/>
                        <img src={hydracadLogo} alt={"HydraCAD Logo"} style={{width: isMobile ? '16%' : '13%'}} className={isMobile ? "cmpp-software-image-mobile" : "cmpp-software-image-desktop"}/>
                        {isMobile && <img src={srpinkcadLogo} alt={"sprinkCAD Logo"} style={{width: isMobile ? '60%' : '25%'}} className={isMobile ? "cmpp-software-image-mobile" : "cmpp-software-image-desktop"}/>}
                    </div>
                </div>
                <div id={isMobile ? "cmpp-look-section-mobile" : "cmpp-look-section-desktop"}>
                    <img alt={"Gradient blue color background"} src={CM_Gradient}/>
                    <div id={isMobile ? "cmpp-look-container-mobile" : "cmpp-look-container-desktop"}>
                        <h3>Take a <span className="gradient-text">look</span> around</h3>
                        <div id={isMobile ? "cmpp-revit-model-mobile" : "cmpp-revit-model-desktop"}>
                            <RevitModel mobile={isMobile}/>
                        </div>
                        <p>NOTE: This is a simplified model showcasing a single viewpoint marked by Clask Marker in an isolated view. This is not representative of Clash Marker's full abilities.</p>
                    </div>
                </div>
                <div id={isMobile ? "cmpp-buy-section-mobile" : "cmpp-buy-section-desktop"}>
                    <div id={isMobile ? "cmpp-buy-container-mobile" : "cmpp-buy-container-desktop"}>
                        <div>
                            <h4>You've read enough. Get Clash Marker here:</h4>
                            <motion.button whileHover={{opacity: [1, 0.75]}} onClick={() => {window.location.href = window.location.origin + "/store?product=clashmarker"}} id={isMobile ? "cmpp-get-cm-button-mobile" : "cmpp-get-cm-button-desktop" }><h4 className="gradient-text">Get Clash Marker</h4></motion.button>
                        </div>
                        <div>
                            <h4>Still unsure? Try Clash Marker here:</h4>
                            <motion.button whileHover={{opacity: [1, 0.75]}} onClick={() => {window.location.href = window.location.origin + "/clashmarkerfreetrial"}} id={isMobile ? "cmpp-try-cm-button-mobile" : "cmpp-try-cm-button-desktop" }><h4 className="gradient-text">Try Clash Marker</h4></motion.button>
                        </div>
                        <p>If you have more questions about Clash Marker you can contact us <Link style={{color: '#3294ce'}} to={"/contactus"}>here.</Link></p>
                        <p>Clash Marker is a patent pending product.</p>
                    </div>
                </div>
            </main>
            <SiteMap isMobile={isMobile}/>
        </div>
    )
}

export default ProductPageClashMarker