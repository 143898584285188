import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion'

//CSS
import '../css/Profile.css'

//Images
import closeIcon from '../images/closeIcon.png'
import confusedScientist from '../images/confusedScientist.png';

//Icons
import { HiDownload } from "react-icons/hi";
import { HiCheck } from "react-icons/hi";
import CopyKeyButton from './CopyKeyButton'

//Firebase
import { onAuthStateChanged } from 'firebase/auth'
import { auth, database } from '../firebase/firebaseClient'
import { collection, getDocs, query, where } from 'firebase/firestore'

function OrderInformation({ orderObject, close, translate }) {

    const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const [copyingAllKeys, setCopyingAllKeys] = useState(false);
    const [copyError, setCopyError] = useState(false);
    const [errorKey, setErrorKey] = useState("");
    const [activeKeys, setActiveKeys] = useState(false);
    const [subStatus, setSubStatus] = useState("");

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const licenseKeysRef = collection(database, `license_keys`);
                const q = query(licenseKeysRef, where('__name__', '==', orderObject.license_keys[0]));

                const snapshot = await getDocs(q);

                if(!snapshot.empty){
                    setActiveKeys(snapshot.docs[0].data().paymentActive);
                }

                const userOrderRef = collection(database, `users/${auth.currentUser.uid}/user_orders`);
                const uq = query(userOrderRef, where("__name__", '==', orderObject.id))

                const uSnapshot = await getDocs(uq);

                const subRef = collection(database, `users/${auth.currentUser.uid}/subscriptions`)
                const sq = query(subRef, where('__name__', '==', uSnapshot.docs[0].data().subID))

                const sSnapshot = await getDocs(sq)

                setSubStatus(sSnapshot.docs[0].data().status.toUpperCase())
            }
        })

        return () => {
            unsub();
        };
    }, [orderObject])

    function addCommas(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function copyAllKeys() {
        let keyString = orderObject.license_keys.join("\n");
        setCopyingAllKeys(true);

        try {
            navigator.clipboard.writeText(keyString).catch((err) => {
                setCopyError(true);
                setErrorKey(keyString);
                return;
            });
        } catch (err) {
            setCopyError(true);
            setErrorKey(keyString);
        }

        setTimeout(() => { setCopyingAllKeys(false) }, 2500);
    }

    return (

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '100%', height: '100lvh', backgroundColor: 'rgba(0,0,0,0.25)', zIndex: 15, backdropFilter: 'blur(5px)', transform: `translateY(${translate}px)` }}>
            <div id={isMobile ? 'order-information-container-mobile' : 'order-information-container-desktop'}>
                <motion.img whileHover={{ opacity: [1, 0.65] }} src={closeIcon} onClick={close} />
                <h2>Order #<span style={{ color: '#508B68' }}>{orderObject.id}</span></h2>
                <h3>Product: <span style={{ marginLeft: '8px', fontWeight: 400 }}>{orderObject.product}</span></h3>
                <h3>Quantity: <span style={{ marginLeft: '8px', fontWeight: 400 }}>{orderObject.quantity}</span></h3>
                <h3>Subscription Type: <span style={{ marginLeft: '8px', fontWeight: 400 }}>{orderObject.subType.substring(0, 2).toUpperCase() + orderObject.subType.substring(2)}</span></h3>
                <h3>Total: <span style={{ marginLeft: '8px', fontWeight: 400 }}>${addCommas(orderObject.total)}</span></h3>
                <h3>Subscription Status: <span style={{ marginLeft: '8px', fontWeight: 400 }}>{subStatus}</span></h3>
                <div id={isMobile ? 'license-keys-header-mobile' : 'license-keys-header-desktop'}>
                    <h3>Product Keys:</h3>
                    {!copyingAllKeys && 
                        <motion.button whileHover={{ opacity: !activeKeys ? [0.25, 0.25] : [1, 0.65] }} onClick={copyAllKeys} disabled={!activeKeys} style={{opacity: !activeKeys ? 0.25 : 1, cursor: !activeKeys ? 'not-allowed' : 'pointer'}}>
                            Copy All <HiDownload style={{ transform: 'translateY(5%)' }} />
                        </motion.button>
                    }
                    {copyingAllKeys && 
                        <motion.button whileHover={{ opacity: [1, 0.65] }} onClick={copyAllKeys}>
                            Copied All <HiCheck style={{ transform: 'translateY(5%)' }} />
                        </motion.button>
                    }
                </div>
                <div id={isMobile ? 'license-keys-container-mobile' : 'license-keys-container-desktop'}>
                    {orderObject.license_keys.map(key => {
                        return (
                            <div key={key} class={isMobile ? "key-container-mobile" : "key-container-desktop"}>
                                <div class={isMobile ? "key-cover-mobile" : "key-cover-desktop"}><p> </p></div>
                                <p key={key}>{isMobile ? key.substring(0,20) : key.substring(0, 30)}...</p>
                                <CopyKeyButton licenseKey={key} error={(errorState, key) => { setCopyError(errorState); setErrorKey(key); }} disabled={activeKeys}/>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
            {copyError &&
                <div id={isMobile ? "copy-error-bg-mobile" : "copy-error-bg-desktop"}>
                    <div id={isMobile ? "copy-error-container-mobile" : "copy-error-container-desktop"}>
                        <motion.img id={isMobile ? "copy-error-close-mobile" : "copy-error-close-desktop"} whileHover={{ opacity: [1, 0.65] }} src={closeIcon} onClick={() => { setCopyError(false); setErrorKey(""); }} alt="Circle with X in the middle for exiting" />
                        <img src={confusedScientist} alt="Confused Scientists scratching their head" />
                        <h4>There was an error automatically copying your key(s) to the clipboard! Please copy it manually below:</h4>
                        <p>{errorKey}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default OrderInformation