import React from 'react'
import {useState} from 'react'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

//CSS
import '../css/ItemPurchaseWindow.css'

//Image
import closeIcon from '../images/closeIcon.png'

import purchaseHeaderImage from '../images/clashmarkerFPBannerTitle.jpg';
import purchaseHeaderImageMobile from '../images/clashMarkerFPBannerMobileTitle.jpg';

function ClashMarkerPurchaseWindow({closeWindow, checkout}) {

    const [productAmount, setProductAmount] = useState(1);
    const [subType, setSubType] = useState("$599.99 / year");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    function updateProductAmount(e){
        if(e.target.value === '0'){
            setProductAmount(1);
        }
        else{
            setProductAmount(e.target.value);
        }
    }

    function increaseProductAmt(){
        setProductAmount(oldAmount => oldAmount + 1);
    }

    function decreaseProductAmt(){
        if(productAmount === 1) return;
        setProductAmount(oldAmount => oldAmount - 1);
    }

    function saveToCart(){
        if(productAmount === '0' || productAmount.toString().length === 0){
            setProductAmount(1);
            return;
        }
        else{
            localStorage.removeItem("tps-cart");
            localStorage.setItem("tps-cart", JSON.stringify({productName: "Clash Marker", amt: productAmount, subType: subType}));
            window.location.href = window.location.href + "/checkout";
        }
    }

    return (
        <div id="ipw-purchase-window-main">
            <motion.img whileHover={{opacity: [1, 0.5]}} onClick={closeWindow} id={isMobile ? "ipw-close-icon-mobile" : "ipw-close-icon-desktop" } src={closeIcon}/>
            <motion.div animate={{opacity:[0,1], y:[15, 0], transition: {duration: 0.75,}}} id={isMobile ? "ipw-item-purchase-container-mobile" : "ipw-item-purchase-container-desktop"}>
                <div id={isMobile ? "ipw-header-container-mobile" : "ipw-header-container-desktop"}>
                    <img src={isMobile ? purchaseHeaderImageMobile : purchaseHeaderImage} alt="Clash Marker promotional header"/>
                    <div id={isMobile ? "ipw-header-choice-container-mobile" : "ipw-header-choice-container-desktop"}>
                        <h1>Clash Marker</h1>
                        <p>Automatic tool for translating clashes from 3D coordination software to your models in AutoCAD, Revit, HydraCAD, BricsCAD, & sprinkCAD.</p>
                        <div id={isMobile ? "ipw-price-selection-container-mobile" : "ipw-price-selection-container-desktop"}>
                            <select class={isMobile ? "ipw-select-style-mobile" : "ipw-select-style"} onChange={(e)=>{setSubType(e.target.value);}}>
                                <option value="$599.99 / year">$599.99 <span style={{color: 'red'}}>/ year</span></option>
                                <option value="$64.99 / month">$64.99 <span style={{color: 'red'}}>/ month</span></option>
                            </select>
                            <div id={isMobile ? "ipw-quantity-container-mobile" : "ipw-quantity-container-desktop"}>
                                <button style={{all: 'unset'}}onClick={decreaseProductAmt}><p>-</p></button>
                                <input placeholder='1' defaultValue={productAmount} value={productAmount} onChange={(e)=>{updateProductAmount(e);}}/>
                                <button style={{all: 'unset'}}onClick={increaseProductAmt}><p>+</p></button>
                                <p style={{marginLeft: '5%'}}>license{productAmount > 1 ? "s" : ""}</p>
                            </div>
                            <motion.button whileHover={{opacity: [1, 0.75]}} onClick={saveToCart} id={isMobile ? "ipw-add-button-mobile" : "ipw-add-button-desktop"} style={{width: isMobile ? '100%' : undefined, marginInline: isMobile ? '32px' : undefined, boxSizing: isMobile ? 'border-box' : undefined}}>
                                <p>Purchase</p>
                            </motion.button>
                        </div>
                    </div>
                </div>
                <div id={isMobile ? "ipw-details-container-mobile" : "ipw-details-container-desktop"}>
                    <section id="try-clashmarker" style={{marginTop: '3%'}}>
                        <div id={isMobile ? "ipw-try-clashmarker-container-mobile" : "ipw-try-clashmarker-container"}>
                            <h3>Get a 2 week free trial of Clash Marker now!</h3>
                            <motion.button whileHover={{opacity: [1, 0.65]}} onClick={()=>{window.location.href = window.location.origin + "/clashmarker"}}><p style={{fontSize: isMobile ? '4vw' : undefined}}>Try Clash Marker</p></motion.button>
                        </div>
                    </section>
                    <section id="overview">
                        <h3>Overview</h3>
                        <p>Clash Marker is an innovative software tool designed to revolutionize the clash resolution experience between Autodesk Navisworks and AutoCAD, BricsCAD, SprinkCAD, HydraCAD, and Revit. It allows designers, engineers, and coordinators to work at astronomical speeds compared to their previous methods of clash resolution.</p>
                    </section>
                    <section id="whatsincluded">
                        <h3>What's Included</h3>
                        <p>Your subscription comes with:</p>
                        <ul>
                            <li><strong>The Clash Marker software</strong></li>
                            <li><strong>The Clash Marker Navisworks Plugin</strong></li>
                            <li><strong>The Clash Marker Revit Plugin</strong></li>
                        </ul>
                    </section>
                    <section id="keyFeatures">
                        <h3>Key Features</h3>
                        <ol>
                            <li>Seamless Integration between Navisworks and AutoCAD, BricsCAD, HydraCAD, SprinkCAD, and Revit</li>
                                <ul>
                                    <li>Clash Marker allows users to effortlessly translate complex 3D clash reports from Navisworks into the 2D environments of AutoCAD, BricsCAD, HydraCAD, and SprinkCAD, as well as the 3D environment of Revit.</li>
                                </ul>
                            <li>Comprehensive Viewpoint Report Translation</li>
                                <ul>
                                    <li>Using the Clash Marker Navisworks plug-in, Clash Marker is able to extract viewpoint data and mark the location and orientation directly onto your floor plan in its supported software.</li>
                                    <li>Clash Marker is able to create comprehensive and unique 3D views in Revit to isolate your viewpoints and make them easier to find and resolve.</li>
                                </ul>
                            <li>Effortless Marking on All Design Suites</li>
                                <ul>
                                    <li>Users can import multiple clash reports at once into Clash Marker, allowing you to choose a unique symbol and color for each clash type to differentiate between all of your clash tests.</li>
                                </ul>
                            <li>Advance Compatibility</li>
                                <ul>
                                    <li>Clash Marker is equipped to handle Navisworks files with custom origin points and transforms, ensuring accuracy even when working with models based off non-standard coordinates.</li>
                                </ul>
                            <li>User-Friendly Interface</li>
                                <ul>
                                    <li>A streamlined, intuitive interface allows for easy navigation and use, making it accessible for professionals at all skill levels.</li>
                                </ul>
                        </ol>
                    </section>
                    <section id="benefits">
                        <h3>Benefits</h3>
                        <ul>
                            <li><strong>Enhanced Accuracy: </strong>Minimize errors and rework by precisely identifying and resolving clashes in the early stages of design.</li>
                            <li><strong>Time and Cost Efficiency: </strong>Reduce the time spent on finding clashes on your model, Clash Marker speeds up the design process and cuts down costs.</li>
                            <li><strong>Improved Collaboration: </strong>Facilitate clearer communication and collaboration among team members by providing easy-to-understand 2D representations of clashes.</li>
                        </ul>
                    </section>
                    <section id="idealfor">
                        <h3>Ideal for</h3>
                        <ul>
                            <li>Coordinators, Engineers, and Designers seeking efficient solutions for clash resolution in their software of choice.</li>
                            <li>Professionals in construction and building design requiring accurate and quick resolution of potential design conflicts.</li>
                        </ul>
                    </section>
                    <section id="reelemin">
                        <h4>Get Clash Marker today and transform the way you handle design clashes, ensuring a smoother, more efficient design process.</h4>
                    </section>
                    <section id="moreInfo" style={{paddingBottom: isMobile ? "5%" : undefined}}>
                        <p>Want to hear more about Clash Marker? Check out our info page on Clash Marker <Link style={{color: '#508B68'}} to={"/clashmarker"}>here</Link>.</p>
                        <p>Have questions about Clash Marker? Check out our FAQs <Link style={{color: '#508B68'}} to={"/clashmarker"}>here</Link>, or <Link style={{color: '#508B68'}} to={"/contactus"}>contact us</Link>.</p>
                    </section>
                </div>
            </motion.div>
        </div>
    )
}

export default ClashMarkerPurchaseWindow