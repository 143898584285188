import React from 'react';
import {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import emailjs from '@emailjs/browser';
import {motion, useAnimation} from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'

//Icons
import { HiMenu } from 'react-icons/hi';

//CSS
import '../css/ContactUs.css'

//Components
import SiteMap from '../Components/SiteMap';
import MobileNavigationWindow from '../Components/MobileNavigationWindow';

//Images
import initialsLogo from '../images/TPSLogo+Initials.png'
import MainNavBar from '../Components/MainNavBar';

function ContactUs() {

    const navModalAnim = useAnimation();
    const [navModal, setNavModal] = useState(false);
    ReactGA.initialize("G-SZLZLTXLRV");

    useEffect(()=>{
        ReactGA.send({
            hitType: "pageview",
            page: "/contactus",
            title: "Contact Us"
        });
        
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.65
        };

        emailjs.init('zLZlONpT1kLop60cT');

        navModalAnim.start("visible");

        function callbackFunc(entries, observerFour){
            entries.forEach(entry => {
                switch(entry.target.id){
                    case "address-container":
                        if(entry.isIntersecting){
                            document.getElementById("address-container").classList.remove("hidden-container");
                            document.getElementById("address-container").classList.add("fadeIn-envelope");
                        }
                        break;
                    case "address-title":
                        if(entry.isIntersecting){
                            document.getElementById("address-title").classList.remove("hidden-container");
                            document.getElementById("address-title").classList.add("fadeIn-title");
                        }
                        break;
                    default:
                        break;
                }
            });
        }

        let observer = new IntersectionObserver(callbackFunc, options);

        const allElements = document.querySelectorAll('.hidden-container');

        allElements.forEach((element)=>{observer.observe(element);});

    }, [navModalAnim]);

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    const handleSubmit = (e) =>{

        let btn = document.getElementById("submit-button")

        if(btn == null){
            btn = document.getElementById("submit-button-mobile");
        }

        e.preventDefault();

        btn.value = 'Sending...';

        const serviceID = 'service_k5y3j2c';
        const templateID = 'template_kkubyzj';

        emailjs.sendForm(serviceID, templateID, e.target)
            .then(() => {
                btn.value = 'Send Email';
                alert('Your message was sent to the TPS Team!');
            
                let from, email, msg;

                if(!isMobile){
                    from = document.getElementById("from_name");
                    email = document.getElementById("from_email");
                    msg = document.getElementById("message");
                }
                else{
                    from = document.getElementById("from_name-mobile");
                    email = document.getElementById("from_email-mobile");
                    msg = document.getElementById("message-mobile");
                }

                from.value = "";
                email.value = "";
                msg.value = "";        

                return;

        }, (err) => {
            btn.value = 'Send Email';
            alert("There was a problem sending a message to our team. Please try again or send us an email directly at: contact@tps-technology.com");

            ReactGA.event({
                category: "contact_form_error",
                action: `Contact form failed to submit: ${err}`,
            });

            return;
        });

    }

    const naval = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 0.5}}}

    function showHideModal(){
        if(navModal){
            navModalAnim.start("hidden").then(()=>{
                setNavModal(false);
            });
        }
        else{
            setNavModal(true);
            navModalAnim.start({x: 500});
        }
    }

    return (
        <div>
            <header>
                {!isMobile && 
                    <MainNavBar/>
                }
                {isMobile && 
                    <nav className={"nav-mobile"}>
                        <Link to={"/"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile"/>
                        </Link>
                        <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={()=>showHideModal()}/>
                    </nav>
                }
            </header>
            {isMobile && navModal && 
                <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim}/>
            }
            <div className={isMobile ? "contact-parent-container-mobile" : "contact-parent-container"}>
                <h2 id={isMobile ? "contact-help-from-header-mobile" : "contact-help-form-header"}>Reach out to our <span className="gradient-text-green" style={{textShadow: 'none'}}>team.</span></h2>
                <form id={isMobile ? "contact-help-form-mobile" : "contact-help-form"} onSubmit={handleSubmit}>
                <div class={isMobile ? "contact-name-email-container-mobile" : "contact-name-email-container"}>

                    <div>
                        <label id={isMobile ? "contact-name-label-mobile" : "contact-name-label"} for="from_name">Name: </label>
                        <input type="text" name="from_name" id={isMobile ? "contact-from_name-mobile" : "contact-from_name"} placeholder="John Doe"/>
                    </div>

                    <div>
                        <label id={isMobile ? "contact-email-label-mobile" : "contact-email-label"} for="from_email">Email Address:</label>
                        <input type="text" name="from_email" id={isMobile ? "contact-from_email-mobile" : "contact-from_email"} placeholder="doe.john@gmail.com"/>
                    </div>
                </div>

                <label id={isMobile ? "contact-message-label-mobile" : "contact-message-label"} for="message" style={{marginTop: isMobile ? 15 : 25,}}>Message to the TPS team:</label>
                <textarea type="text" name="message" id={isMobile ? "contact-message-mobile" : "contact-message"} placeholder='Please include a detailed message of why you are reaching out and how we can help...'/>

                <motion.input whileHover={{opacity:[1, 0.75]}} type="submit" id={isMobile ? "contact-submit-button-mobile" : "contact-submit-button"} value="Send Email"/>
                </form>
            </div>
            <footer id={isMobile ? "footer-container-mobile" : "footer-container-desktop"}>
                    <SiteMap isMobile={isMobile}/>
            </footer>
        </div>
    )
}

export default ContactUs