import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

//CSS
import "../css/MainNavBar.css"

//Images
import tpsFullLogo from '../images/TPSLogo+Name.png'


export default function MainNavBar() {
    return (
        <nav style={{minWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'column'}}>
            <div id={"main-nav-bar-container"}>
                <Link to={"/"}><img src={tpsFullLogo} id={"main-nav-bar-logo"} alt={"Transparent Software Logo"}/></Link>
                <motion.div animate={{opacity: [0, 1]}} id={"main-nav-bar-links-container"}>
                    <Link to={"/#whatweoffer"} className="link-hover"><p>Services</p></Link>
                    <Link to={"/#whatwedo"} className="link-hover"><p>What We Do</p></Link>
                    <Link to={"/#explore"} className="link-hover"><p>Our Work</p></Link>
                    <Link to={"/contactus"} id={"main-nav-bar-contact-button"}>
                        <p>Contact Us</p>
                    </Link>
                    <Link to={"/store"} id={"main-nav-bar-shop-button"}>
                        <p>Shop</p>
                    </Link>
                </motion.div>
            </div>
        </nav>
    )
}
